.react-datepicker {
  font-family: "ProximaNova";
  background-color: #FFFFFF;
  color: #000000;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.15);
  padding: 12px;
}

.react-datepicker__header {
  border-bottom: 0;
  background-color: transparent;
}

.react-datepicker__day {
  border: 2px solid transparent;
  border-radius: 4px;
  margin: 1px;
}

.react-datepicker__day:hover {
  border: 2px solid rgba(37, 121, 186, 1);
  background-color: transparent;
}

.react-datepicker__day:active {
  border: 2px solid rgba(37, 121, 186, 1);
  background-color: transparent;
  box-shadow: inset 1px 1px rgba(193, 199, 208, 1);
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-range {
  background-color: rgba(37, 121, 186, 1);
}

.react-datepicker__day--in-selecting-range {
  background-color: rgba(37, 121, 186, 1);
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background-color: rgba(173, 233, 228, 1);
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__day--in-selecting-range:hover {
  background-color: rgba(37, 121, 186, 1);
}

.datePicker-input {
  width: 100%;
  border: 1px solid rgba(193, 199, 208, 1);
  border-radius: 4px;
  height: 40px;
  padding-right: 12px;
  padding-left: 12px;
  font-family: "ProximaNova";
  outline: none;
  box-sizing: border-box;
}

.datePicker-input:hover {
  box-shadow: 0px 0px 0px 2px #00bfae;
  outline: none;
}

.datePicker-input:active {
  box-shadow: 0px 0px 0px 2px #00bfae, 0px 0px 0px 6px #def6f5;
  outline: none;
}

.datePicker-input:focus {
  box-shadow: 0px 0px 0px 2px #00bfae, 0px 0px 0px 6px #def6f5;
  outline: none;
}

.datePicker-input-disabled {
  width: 100%;
  height: 40px;
  padding-right: 12px;
  padding-left: 12px;
  font-family: "ProximaNova";
  outline: none;
  background: #fafbfc;
  border: 1px solid #dfe1e6;
  border-radius: 4px;
  color: rgba(193, 199, 208, 1);
  box-sizing: border-box;
}

.datePicker-input-start,
.datePicker-input-disabled-start {
  border: none;
}

.datePicker-input-end,
.datePicker-input-disabled-end {
  border: none;
}
